import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image"
import PropTypes from "prop-types"


const Clients = ({ title, items }) => (
  <div className="bg-white">
    <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10 text-center">
        {title ?? "Ils nous font confiance"}
      </h2>
      <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
        {items.map((client) => (
          <div
            className="col-span-1 flex justify-center py-8 px-8 bg-gray-50"
            key={client.name}
          >
            <Img
              className="h-12 flex-1"
              fluid={client.image.childImageSharp.fluid}
              objectFit="contain"
              alt={client.name}
              imgStyle={{objectFit: `contain` }}
            />
          </div>
        ))}
        <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
          <Link
            to="/contactez-nous/"
            title="contactez-nous"
            className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10"
          >
            Vous ?
          </Link>
        </div>
      </div>
    </div>
  </div>
);

Clients.propTypes = {
  title: PropTypes.string,
  items: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    name: PropTypes.string,
  }),
};

export default Clients;