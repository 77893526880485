import React from "react";

function Reassurance() {

  return (


  <div className="bg-blue-600 text-white">
  <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
    <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">


    {
      [

        {
          link: `/`,
          title: `Rapidité et efficacité`,
          description: `Votre besoin est urgent? EVONERGY le gère!`,
          icon: <svg className="h-10 w-10 text-blue-600" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24"><path d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
        },
        {
          link: `/`,
          title: `Prix transparents`,
          description: `Nous vous garantissons le prix le plus juste, en toute transparence`,
          icon: <svg className="h-10 w-10 text-blue-600" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24"><path d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"></path></svg>
        },
        {
          link: `/`,
          title: `Travail soigné Garanti`,
          description: `Evonergy, c'est la garantie d'une qualité de travail irréprochable`,
          icon: <svg className="h-10 w-10 text-blue-600" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24"><path d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path></svg>
        }
    ].map((item) => (

      <div className="col-span-1 md:col-span-2 lg:col-span-1" key={item.title}>
        <div className="px-2 py-4 sm:p-2">
          
            <div className="flex items-center">
    <div className="flex-shrink-0 bg-white rounded-full p-3">{item.icon}</div>
              <div className="ml-5 w-0 flex-1">
                <div className="text-lg md:text-xl leading-8 font-bold uppercase">
                {item.title}
                  </div>
                <div className="text-sm leading-5 font-semibold">
                      {item.description}
                </div>
              </div>
            </div>
          </div>
      </div>

          ))}

    </div>
  </div>
</div>

  );
}

export default Reassurance;