import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";
import Presentation from "../components/presentation";
import Features from "../components/features";
import Reassurance from "../components/reassurance";
import Clients from "../components/clients";
import CallToAction from "../components/calltoaction";

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <SEO
        title={
          frontmatter.meta_title
            ? frontmatter.meta_title
            : ""
        }
        description={
          frontmatter.meta_description
            ? frontmatter.meta_description
            : ""
        }
      />
      <Hero content={frontmatter.intro} />
      <div className="relative bg-gray-50 pt-8 pb-10 px-4 sm:px-6 lg:pt-12 lg:pb-14 lg:px-8">
        <div className="absolute inset-0">
          <div className="h-1/3 sm:h-2/3"></div>
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
              {frontmatter.prestations_block.title
                ? frontmatter.prestations_block.title
                : "Nos prestations"}
            </h2>
          </div>
          <Features items={frontmatter.prestations_block.prestations} />
        </div>
      </div>

      <Clients
        title={frontmatter.partenaires_block.title}
        items={frontmatter.partenaires_block.partenaires}
      />
      <Reassurance />
      <CallToAction />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
         query IndexPageTemplate {
           markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
             frontmatter {
               intro {
                 heading
                 description
                 image {
                   childImageSharp {
                     fluid(maxWidth: 1300, quality: 80) {
                       ...GatsbyImageSharpFluid_withWebp
                     }
                   }
                 }
               }
               presentation {
                 title
                 description
               }
               prestations_block {
                 title
                 prestations {
                   image {
                     childImageSharp {
                       fluid(maxWidth: 450, quality: 80) {
                         ...GatsbyImageSharpFluid_withWebp
                       }
                     }
                   }
                   title
                   text
                 }
               }
               partenaires_block {
                 title
                 partenaires {
                   image {
                     childImageSharp {
                       fluid(maxWidth: 250, quality: 100) {
                         ...GatsbyImageSharpFluid
                       }
                     }
                   }
                   name
                 }
               }

               meta_title
               meta_description
             }
           }
         }
       `;
