import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image"
import PropTypes from "prop-types"
import Html from "../components/html"


const Hero = ({ content }) => (
  <div className="relative bg-blue-200 overflow-hidden">
    <div className="max-w-screen-xl mx-auto bg-blue-200">
      <div className="relative z-10 pb-8 bg-blue-200  sm:pb-16 md:pb-12 lg:max-w-2xl lg:w-full">
        <svg
          className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-blue-500 transform translate-x-1/2"
          fill="currentColor"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon points="50,0 100,0 50,100 0,100" />
        </svg>
        <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
          <div className="sm:text-center lg:text-left pt-10">
            <h1 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none">
              {content.heading}
            </h1>
            <article className="prose prose-sm">
              <Html
                className="mt-3 text-base text-gray-700 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0"
                content={content.description}
              />
            </article>

            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div>
                <Link
                  to="/contactez-nous/"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent shadow-xl text-base leading-6 font-medium text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                >
                  Demandez un devis
                </Link>
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <Link
                  to="/nos-prestations/"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium shadow-xl text-gray-900 bg-gray-200 hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:border-gray-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                >
                  Nos prestations
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <Img
        className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
        fluid={content.image.childImageSharp.fluid}
        alt="Travaux d'électricité / climatisation"
      />
    </div>
  </div>
);

Hero.propTypes = {
  content: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    description: PropTypes.string,
    heading: PropTypes.string,
  }),
};

export default Hero